const pathRoutes = {
  auth: "/",
  donwload: "/downloads",
  dashboard: "/dashboard",
  clientes: "/dashboard/clientes",
  cadastarCliente: "/dashboard/clientes/cadastrar",
  listaCliente: "/dashboard/clientes/lista",
  venda: "/dashboard/clientes/vendas",
  notFound: "*",
};

export default pathRoutes;
