import React, { useContext, useState } from "react";
import pathRoutes from "../path/pathRoutes";
import { useNavigate } from "react-router-dom";
import Loader from "./global/Loader";
import ApiContext from "../context/api/apiContext";
import useApi from "../hooks/useApi";

import MyModal from "../components/modais/Modal";
import useModal from "../hooks/useModal";

const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const { url_api, user, setUser } = useContext(ApiContext);

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Hooks
  //===============================================================================================

  // Conexao com API
  const { loading, fetchData } = useApi();

  //==================================================================================
  // Modal
  const {
    openModal,
    showModal,
    onHide,
    type,
    types,
    title,
    body,
    handleConfirm,
  } = useModal();

  //==================================================================================

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓

  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await fetchData(`${url_api}/auth`, "POST", {
        usuario,
        password,
      });
      if (data.result) {
        // openModal("Login", data.message, () => onHide, types.success);
        setUser(data.data[0]);
        navigate("/dashboard");
      } else {
        openModal("Login", data.message, () => onHide, types.alert);
      }
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal("Login", error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
  };

  if (loading) {
    return <Loader isLoader={loading} />;
  }

  return (
    <>
      {showModal && (
        <MyModal
          show={showModal}
          onHide={onHide}
          type={type} // ou "success", "alert", "question", ou "error"
          title={title}
          body={body}
          onConfirm={handleConfirm}
        />
      )}

      <div className="login">
        <div className="login-container">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Usuario:</label>
            <input
              type="text"
              id="text"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              required
            />
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Login</button>
          </form>
          <button onClick={() => navigate(pathRoutes.donwload)}>
            Downloads
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
