import React from "react";
import ApiProvider from "./api/apiProvider";
import VendasProvider from "./vendas/vendasProvider";

const CombinedProvider = ({ children }) => {
  return (
    <ApiProvider>
      <VendasProvider>
        {/* Adicione mais Providers conforme necessário */}
        {children}
      </VendasProvider>
    </ApiProvider>
  );
};

export default CombinedProvider;
