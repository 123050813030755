import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../../components/navBar/navBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Dashboard() {
  const navigate = useNavigate();

  return (
    <Container
      fluid
      style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}
    >
      <Row style={{ padding: 0, backgroundColor: "#0d6efd" }}>
        <Col>
          {" "}
          <NavBar key={0} placement={"start"} name={"Menu"} />
        </Col>
      </Row>
      <Row style={{ flexGrow: "1" }}>
        <Outlet />
      </Row>
    </Container>
  );
}

export default Dashboard;
