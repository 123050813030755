import { Divider } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Table from "react-bootstrap/Table";

import useApi from "../../hooks/useApi";
import useModal from "../../hooks/useModal";
import MyModal from "../../components/modais/Modal";

import ApiContext from "../../context/api/apiContext";

import Loader from "../global/Loader";
import pathRoutes from "../../path/pathRoutes";

import VendasContext from "../../context/vendas/vendasContext";

function ListaCliente() {
  const navigate = useNavigate();

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Contextos
  //===============================================================================================
  const { url_api, user } = useContext(ApiContext);
  const { cliente, setCliente } = useContext(VendasContext);

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Hooks
  //===============================================================================================

  // Conexao com API
  const { loading, fetchData } = useApi();

  //==================================================================================
  // Modal
  const {
    openModal,
    showModal,
    onHide,
    type,
    types,
    title,
    body,
    handleConfirm,
  } = useModal();

  //==================================================================================
  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Stados
  //===============================================================================================
  const [empresas, setEmpresas] = useState("");

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Requisições
  //===============================================================================================
  const getAllClients = async () => {
    console.log("oi");
    try {
      const data = await fetchData(`${url_api}/cliente/lista`);
      console.log(data);
      if (data.result) {
        setEmpresas(data.data);
      } else {
        openModal("Lista Clientes", data.message, () => onHide, types.alert);
      }
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal("Lista Clientes", error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
  };

  const updateStatus = async (cpf_cnpj, status) => {
    const dados = {
      cpf_cnpj: cpf_cnpj,
      status: status,
    };

    try {
      const data = await fetchData(`${url_api}/cliente/update`, "PUT", dados);
      console.log(data);
      if (data.result) {
        openModal(
          "Registro Cliente",
          data.message,
          () => onHide,
          types.success
        );
      } else {
        openModal("Registro Cliente", data.message, () => onHide, types.alert);
      }
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal("Registro Cliente", error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
    getAllClients();
  };

  const deleteStatus = async (id) => {
    const dados = {
      id: id,
    };

    try {
      const data = await fetchData(
        `${url_api}/cliente/delete`,
        "DELETE",
        dados
      );
      console.log(data);
      if (data.result) {
        openModal(
          "Registro Cliente",
          data.message,
          () => onHide,
          types.success
        );
      } else {
        openModal("Registro Cliente", data.message, () => onHide, types.alert);
      }
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal("Registro Cliente", error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
    getAllClients();
  };

  useEffect(() => {
    const requires = async () => {
      getAllClients();
    };
    requires();
  }, []);

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓

  if (loading) {
    return <Loader isLoader={loading} />;
  }

  console.log(user);

  return (
    <>
      {showModal && (
        <MyModal
          show={showModal}
          onHide={onHide}
          type={type} // ou "success", "alert", "question", ou "error"
          title={title}
          body={body}
          onConfirm={handleConfirm}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          background: "#333",
          color: "#FFF",
          paddingTop: "30px",
        }}
      >
        <h2>Lista de Clientes</h2>
        <Divider style={{ background: "#fff", marginBottom: "30px" }} />
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Código</th>
              <th>Status</th>
              <th>CPF/CNPJ</th>
              <th>Razão</th>
              <th>Fantasia</th>
              <th style={{ textAlign: "center" }}>Opções</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(empresas) &&
              empresas.map((e, i) => (
                <tr>
                  <td
                    style={{
                      fontWeight: "800",
                      color: "#fff",
                      alignContent: "center",
                      background:
                        e.status === "Ativo"
                          ? "green"
                          : e.status === "Cancelado"
                          ? "red"
                          : "blue",
                    }}
                  >
                    {e.id}
                  </td>
                  <td
                    style={{
                      fontWeight: "800",
                      color: "#fff",
                      alignContent: "center",
                      background:
                        e.status === "Ativo"
                          ? "green"
                          : e.status === "Cancelado"
                          ? "red"
                          : "blue",
                    }}
                  >
                    {e.status}
                  </td>
                  <td
                    style={{
                      fontWeight: "800",
                      color: "#fff",
                      alignContent: "center",
                      background:
                        e.status === "Ativo"
                          ? "green"
                          : e.status === "Cancelado"
                          ? "red"
                          : "blue",
                    }}
                  >
                    {e.cpf_cnpj}
                  </td>
                  <td
                    style={{
                      fontWeight: "800",
                      color: "#fff",
                      alignContent: "center",
                      background:
                        e.status === "Ativo"
                          ? "green"
                          : e.status === "Cancelado"
                          ? "red"
                          : "blue",
                    }}
                  >
                    {e.razao}
                  </td>
                  <td
                    style={{
                      fontWeight: "800",
                      color: "#fff",
                      alignContent: "center",
                      background:
                        e.status === "Ativo"
                          ? "green"
                          : e.status === "Cancelado"
                          ? "red"
                          : "blue",
                    }}
                  >
                    {e.fantasia}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    <DropdownButton id="dropdown-basic-button" title="Ações">
                      <Dropdown.Item
                        onClick={() => {
                          setCliente(e);
                          navigate(pathRoutes.venda);
                        }}
                      >
                        Produtos
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#/action-1">
                        Informações
                      </Dropdown.Item> */}
                      {e.status === "Ativo" && (
                        <>
                          <Dropdown.Item
                            onClick={() =>
                              updateStatus(e.cpf_cnpj, "Cancelado")
                            }
                          >
                            Cancelar
                          </Dropdown.Item>
                        </>
                      )}
                      {e.status === "Inativo" ||
                      e.status === "Cancelado" ||
                      e.status === "Aberto" ? (
                        <>
                          <Dropdown.Item
                            onClick={() => updateStatus(e.cpf_cnpj, "Ativo")}
                          >
                            Ativar
                          </Dropdown.Item>
                        </>
                      ) : (
                        ""
                      )}
                      {user.permissoes && user.permissoes[1] === "T" && (
                        <Dropdown.Item onClick={() => deleteStatus(e.id)}>
                          Excluir
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default ListaCliente;
