import Login from "./routes/Index";
import NotFound from "./components/global/NotFound";
import ListaDeApps from "./routes/Donwload/Download";

import React from "react";
import { Routes, Route } from "react-router-dom";
import pathRoutes from "./path/pathRoutes";
import Dashboard from "./routes/dashboard/Dashboard";
import Home from "./routes/home/Home";
import CadastrarCliente from "./routes/clientes/CadastrarCliente";
import ListaCliente from "./routes/clientes/ListaCliente";
import Vendas from "./routes/clientes/Vendas";

const App = () => {
  return (
    <>
      <Routes>
        <Route exact path={pathRoutes.auth} element={<Login />} />
        <Route path={pathRoutes.donwload} element={<ListaDeApps />} />
        <Route path={pathRoutes.dashboard} element={<Dashboard />}>
          <Route index element={<Home />} />
          <Route
            path={`${pathRoutes.dashboard}${pathRoutes.donwload}`}
            element={<ListaDeApps />}
          />
          <Route
            path={`${pathRoutes.cadastarCliente}`}
            element={<CadastrarCliente />}
          />
          <Route
            path={`${pathRoutes.listaCliente}`}
            element={<ListaCliente />}
          />
          <Route path={`${pathRoutes.venda}`} element={<Vendas />} />
        </Route>
        <Route path={pathRoutes.notFound} element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
