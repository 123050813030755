import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  gap: "22px",
  flexDirection: "column",
  p: 4,
};

export default function MyModal(props) {
  // Renderiza o cabeçalho do modal com base no tipo passado nas props
  const renderHeader = () => {
    switch (props.type) {
      case "success":
        return (
          <Typography id="contained-modal-title-vcenter">
            {props.title}
          </Typography>
        );
      case "alert":
        return (
          <Typography id="contained-modal-title-vcenter">
            {props.title}
          </Typography>
        );
      case "error":
        return (
          <Typography id="contained-modal-title-vcenter">
            {props.title}
          </Typography>
        );
      case "question":
        return (
          <Typography id="contained-modal-title-vcenter">
            {props.title}
          </Typography>
        );
      default:
        return (
          <Typography id="contained-modal-title-vcenter">
            {props.title}
          </Typography>
        );
    }
  };

  // Renderiza o corpo do modal com base no tipo passado nas props
  const renderBody = () => {
    switch (props.type) {
      case "success":
        return <Typography>{props.body}</Typography>;
      case "alert":
        return <Typography>{props.body}</Typography>;
      case "error":
        return <Typography>{props.body}</Typography>;
      case "question":
        return <Typography>{props.body}</Typography>;
      default:
        return <Typography>{props.body}</Typography>;
    }
  };

  // Renderiza os botões do rodapé com base no tipo passado nas props
  const renderFooterButtons = () => {
    if (props.type === "question") {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Button onClick={props.onHide}>Não</Button>
          <Button onClick={props.onConfirm} variant="success">
            Sim
          </Button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Button onClick={props.onHide} variant="success">
            OK
          </Button>
        </div>
      );
    }
  };
  return (
    <Modal
      open={props.show}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {renderHeader()}
        <Divider />
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {renderBody()}
        </Typography>
        <Divider />
        {renderFooterButtons()}
      </Box>
    </Modal>
  );
}
