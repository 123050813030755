import { Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";


function Drop(props) {
  const navigate = useNavigate();
  return (
    <Dropdown>
      {props.value.sub ? (
        <Dropdown.Toggle
          className="btn-primary"
          style={{
            width: "100%",
            borderRadius: "0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          id="dropdown-basic"
        >
          {props.value.nome}
        </Dropdown.Toggle>
      ) : (
        <Button
          onClick={() => {
            navigate(props.value.path);
            props.handleClose();
          }}
          lassName="btn-primary"
          style={{
            width: "100%",
            borderRadius: "0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {" "}
          {props.value.nome}
        </Button>
      )}
      {props.value.sub && (
        <Dropdown.Menu style={{ width: "100%" }}>
          {Array.isArray(props.value.sub) &&
            props.value.sub.map((e, i) => (
              <Dropdown.Item
                style={{ width: "100%" }}
                onClick={() => {
                  navigate(e.path);
                  props.handleClose();
                }}
              >
                {e.value}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
}

export default Drop;
