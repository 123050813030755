import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import "./routes/style/global.css";
import CombinedProvider from "./context/CombinedProvider";

ReactDOM.createRoot(document.getElementById("root")).render(
  <CombinedProvider>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </CombinedProvider>
);
