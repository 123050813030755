import React, { useState } from "react";
import VendasContext from "./vendasContext";

const VendasProvider = ({ children }) => {
  const [cliente, setCliente] = useState(""); // 1 = Desenvolvimento.

  const value = {
    cliente,
    setCliente,
  };

  return (
    <VendasContext.Provider value={value}>{children}</VendasContext.Provider>
  );
};

export default VendasProvider;
