import { useState } from "react";

const useApi = () => {
  const [loading, setLoading] = useState(false);

  const fetchData = async (url, method = "GET", body = null) => {
    setLoading(true);
    try {
      const requestOptions = {
        method,
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (body !== null) {
        requestOptions.body = JSON.stringify(body);
      }

      const response = await fetch(url, requestOptions);

      const responseData = await response.json();
      if (!response.ok) {
        return responseData.message || "Ocorreu um erro.";
      }
      return responseData;
    } catch (error) {
      return error.message;
    } finally {
      setLoading(false);
    }
  };

  return { loading, fetchData };
};

export default useApi;
