import { Divider } from "@mui/material";
import { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import useApi from "../../hooks/useApi";
import useModal from "../../hooks/useModal";
import MyModal from "../../components/modais/Modal";

import ApiContext from "../../context/api/apiContext";

import Loader from "../global/Loader";

function CadastrarCliente() {
  const ufs = [
    { uf: "AC", nome: "Acre" },
    { uf: "AL", nome: "Alagoas" },
    { uf: "AP", nome: "Amapá" },
    { uf: "AM", nome: "Amazonas" },
    { uf: "BA", nome: "Bahia" },
    { uf: "CE", nome: "Ceará" },
    { uf: "DF", nome: "Distrito Federal" },
    { uf: "ES", nome: "Espírito Santo" },
    { uf: "GO", nome: "Goiás" },
    { uf: "MA", nome: "Maranhão" },
    { uf: "MT", nome: "Mato Grosso" },
    { uf: "MS", nome: "Mato Grosso do Sul" },
    { uf: "MG", nome: "Minas Gerais" },
    { uf: "PA", nome: "Pará" },
    { uf: "PB", nome: "Paraíba" },
    { uf: "PR", nome: "Paraná" },
    { uf: "PE", nome: "Pernambuco" },
    { uf: "PI", nome: "Piauí" },
    { uf: "RJ", nome: "Rio de Janeiro" },
    { uf: "RN", nome: "Rio Grande do Norte" },
    { uf: "RS", nome: "Rio Grande do Sul" },
    { uf: "RO", nome: "Rondônia" },
    { uf: "RR", nome: "Roraima" },
    { uf: "SC", nome: "Santa Catarina" },
    { uf: "SP", nome: "São Paulo" },
    { uf: "SE", nome: "Sergipe" },
    { uf: "TO", nome: "Tocantins" },
  ];

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Contextos
  //===============================================================================================
  const { url_api } = useContext(ApiContext);

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Hooks
  //===============================================================================================

  // Conexao com API
  const { loading, fetchData } = useApi();

  //==================================================================================
  // Modal
  const {
    openModal,
    showModal,
    onHide,
    type,
    types,
    title,
    body,
    handleConfirm,
  } = useModal();

  //==================================================================================

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Stados
  //===============================================================================================
  const [razao, setRazao] = useState("");
  const [fantasia, setFantasia] = useState("");
  const [endereco, setEndereco] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [cep, setCep] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [segmento, setSegmento] = useState("");
  const [cpf_cnpj, setCpf_cnpj] = useState("");

  function clearStates(params) {
    setRazao("");
    setFantasia("");
    setEndereco("");
    setCidade("");
    setEstado("");
    setCep("");
    setWhatsapp("");
    setSegmento("");
    setCpf_cnpj("");
  }

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Requisições
  //===============================================================================================
  const submitCliente = async (e) => {
    e.preventDefault();

    const dados = {
      razao: razao,
      fantasia: fantasia,
      endereco: endereco,
      estado: estado,
      cep: cep,
      whatsapp: whatsapp,
      segmento: segmento,
      cpf_cnpj: cpf_cnpj,
    };

    try {
      const data = await fetchData(
        `${url_api}/cliente/register`,
        "POST",
        dados
      );
      console.log(data);
      if (data.result) {
        openModal(
          "Registro Cliente",
          data.message,
          () => onHide,
          types.success
        );
        clearStates();
      } else {
        openModal("Registro Cliente", data.message, () => onHide, types.alert);
      }
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal("Registro Cliente", error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
  };
  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓

  if (loading) {
    return <Loader isLoader={loading} />;
  }
  return (
    <>
      {showModal && (
        <MyModal
          show={showModal}
          onHide={onHide}
          type={type} // ou "success", "alert", "question", ou "error"
          title={title}
          body={body}
          onConfirm={handleConfirm}
        />
      )}
      <Form
        onSubmit={submitCliente}
        style={{ background: "#333", color: "#FFF", paddingTop: "30px" }}
      >
        <h2>Cadastrar Cliente</h2>
        <Divider style={{ background: "#fff", marginBottom: "30px" }} />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Razão</Form.Label>
            <Form.Control
              value={razao}
              onChange={(e) => setRazao(e.target.value)}
              type="text"
              placeholder="Insira a Razão Social"
              required
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Fantasia</Form.Label>
            <Form.Control
              value={fantasia}
              onChange={(e) => setFantasia(e.target.value)}
              type="text"
              placeholder="Insira o Nome Fantasia"
              required
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3" controlId="formGridAddress1">
            <Form.Label>Endereço</Form.Label>
            <Form.Control
              value={endereco}
              onChange={(e) => setEndereco(e.target.value)}
              placeholder="Insira o endereço rua, numero"
              required
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="formGridAddress1">
            <Form.Label>CPF ou CNPJ</Form.Label>
            <Form.Control
              value={cpf_cnpj}
              onChange={(e) => setCpf_cnpj(e.target.value)}
              placeholder="Insira o CPF ou CNPJ"
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3" controlId="formGridAddress2">
            <Form.Label>Whatsapp</Form.Label>
            <Form.Control
              value={whatsapp}
              onChange={(e) => setWhatsapp(e.target.value)}
              placeholder="Insira o contado de Whatsapp do cliente"
              required
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-3" controlId="formGridAddress2">
            <Form.Label>Segmento</Form.Label>
            <Form.Control
              value={segmento}
              onChange={(e) => setSegmento(e.target.value)}
              placeholder="Insira o segmento da Empresa"
              required
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
              placeholder="Insira a cidade"
              required
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>Estado</Form.Label>
            <Form.Select
              defaultValue=""
              value={estado}
              onChange={(e) => setEstado(e.target.value)}
              required
            >
              <option value={""}>Selecione o estado</option>
              {ufs.map((e, i) => (
                <option key={i} value={e.uf}>{`${e.uf} - ${e.nome}`}</option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>CEP</Form.Label>
            <Form.Control
              value={cep}
              onChange={(e) => setCep(e.target.value)}
              placeholder="Insira o CEP"
              required
            />
          </Form.Group>
        </Row>

        {/* <Form.Group className="mb-3" id="formGridCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group> */}

        <Button variant="primary" type="submit">
          Cadastrar
        </Button>
      </Form>
    </>
  );
}

export default CadastrarCliente;
