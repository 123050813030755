import React, { useState } from "react";
import ApiContext from "./apiContext";

const ApiProvider = ({ children }) => {
  const statusApi = 0; // 1 = Desenvolvimento.

  const [user, setUser] = useState([]);

  const url_desenvolvimento = "http://localhost:3002";

  const url_producao = "https://api-dashboard.protechsistema.com.br";

  const url_api = statusApi === 1 ? url_desenvolvimento : url_producao;

  const value = {
    url_api,
    user,
    setUser,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export default ApiProvider;
