import { useState } from "react";

const useModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [confirmFunction, setConfirmFunction] = useState(null);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const openModal = (newTitle, newBody, newConfirmFunction, newType) => {
    setTitle(newTitle);
    setBody(newBody);
    setConfirmFunction(newConfirmFunction);
    setType(newType);
    setShowModal(true);
  };

  const handleConfirm = () => {
    if (confirmFunction) {
      confirmFunction(); // Executa a função passada via onConfirm
    }
    setShowModal(false); // Fecha o modal após a confirmação
  };

  const types = {
    success: "success",
    alert: "alert",
    error: "error",
    question: "question",
  };

  return {
    openModal,
    showModal,
    onHide: () => setShowModal(false),
    type,
    types,
    title,
    body,
    handleConfirm,
  };
};

export default useModal;
