import React, { useState } from "react";
import { Button } from "react-bootstrap";

const TextCopy = ({ text, message }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
      <p>{message}: </p>
      <input type="text" value={text} readOnly />
      <Button onClick={handleCopy}>{copied ? "Copiado!" : "Copiar"}</Button>
    </div>
  );
};

export default TextCopy;
