import React from "react";
import BasicCard from "../../components/cards/BasicCard";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const ListaDeApps = () => {
  const navigate = useNavigate();

  const aplicativos = [
    {
      nome: "Pro Shop",
      descricao: "Pacote de Instalação Sistema Pro Shop",
      link: "https://www.4shared.com/file/D0SD8liXku/setupProShop110.html",
    },
  ];

  return (
    <div
      style={{
        padding: "1%",
        display: "flex",
        flexDirection: "column",
        gap: "22px",
      }}
    >
      <div style={{ display: "flex", gap: "22px" }}>
        <Button
          style={{ backgroundColor: "red", padding: "0 30px", border: "none" }}
          onClick={() => navigate("/")}
        >
          Voltar
        </Button>
        <h2>Lista de Aplicativos</h2>
      </div>
      <div>
        {aplicativos.map((app, index) => (
          <BasicCard
            title={app.nome}
            body={app.descricao}
            button={"Download"}
            link={() => window.open(app.link, "_blank")}
          />
        ))}
      </div>
    </div>
  );
};

export default ListaDeApps;
