import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import pathRoutes from "../../path/pathRoutes";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Drop from "../botoes/Dropdown";

export default function NavBar({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const options = [
    { nome: "Inicio", path: pathRoutes.dashboard },
    {
      nome: "Clientes",
      path: pathRoutes.clientes,
      sub: [
        { value: "Todos", path: pathRoutes.listaCliente },
        { value: "Cadastrar", path: pathRoutes.cadastarCliente },
      ],
    },
    {
      nome: "Funcionários",
      path: pathRoutes.clientes,
      sub: [
        { value: "Todos", path: "" },
        { value: "Cadastro", path: "" },
      ],
    },
    {
      nome: "Financeiro",
      path: pathRoutes.clientes,
      sub: [
        { value: "Cadastro", path: "" },
        { value: "Todos", path: "" },
        { value: "Cadastro", path: "" },
      ],
    },
  ];

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="me-2"
        style={{ display: "flex", alignItems: "center", gap: "10px" }}
      >
        <FormatListBulletedIcon />
        {name}
      </Button>
      <i class="bi bi-list"></i>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Pro Tech Sistemas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
            gap: "1px",
          }}
        >
          {options.map((e, i) => (
            <Drop value={e} handleClose={handleClose} />
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

// function Example() {
//   return (
//     <>
//       {["start", "end", "top", "bottom"].map((placement, idx) => (
//         <OffCanvasExample key={idx} placement={placement} name={placement} />
//       ))}
//     </>
//   );
// }

// render(<Example />);
