import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import useApi from "../../hooks/useApi";
import useModal from "../../hooks/useModal";
import MyModal from "../../components/modais/Modal";
import ApiContext from "../../context/api/apiContext";
import Loader from "../global/Loader";
import pathRoutes from "../../path/pathRoutes";

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import VendasContext from "../../context/vendas/vendasContext";
import { Button } from "react-bootstrap";

import TextCopy from "../../components/botoes/TextCopy";

function Vendas() {
  const navigate = useNavigate();
  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Contextos
  //===============================================================================================
  const { url_api } = useContext(ApiContext);
  const { cliente, setCliente } = useContext(VendasContext);

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Hooks
  //===============================================================================================

  // Conexao com API
  const { loading, fetchData } = useApi();

  //==================================================================================
  // Modal
  const {
    openModal,
    showModal,
    onHide,
    type,
    types,
    title,
    body,
    handleConfirm,
  } = useModal();

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Stados
  //===============================================================================================
  const [produtos, setProdutos] = useState([]);
  const [produto, setProduto] = useState([]);
  const [produtoPrecoBase, setProdutoPrecoBase] = useState(0);

  const [modulos, setModulos] = useState([]);
  const [modulosPerm, setModulosPerm] = useState(
    "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF"
  );

  const [aplicaDesc, setAplicaDesc] = useState(0);
  const [desc_acres, setDesc_acres] = useState(0);

  const [obs, setObs] = useState("");

  const [isEditable, setIsEditable] = useState(false);

  const [dataPagamento, setDataPagamento] = useState(cliente.pagamento_input);

  // Lista de Produtos Cliente
  const [produtosCliente, setProdutosClientes] = useState([]);

  const [isEditableProd, setIsEditableProd] = useState(false);

  const [id_venda, setId_venda] = useState("");

  const clearStadesProd = () => {
    setProduto([]);
    setProdutoPrecoBase(0);
    setModulos([]);
    setModulosPerm("FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF");
    setAplicaDesc(0);
    setDesc_acres(0);
    setId_venda("");
    setObs("");
  };

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Requisições
  //===============================================================================================
  const getAllProducts = async () => {
    console.log("oi");
    try {
      const data = await fetchData(`${url_api}/produtos/lista`);
      console.log(data);
      if (data.result) {
        setProdutos(data.data);
        return data.data[0].id;
      } else {
        openModal("Lista Produtos", data.message, () => onHide, types.alert);
      }
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal("Lista Produtos", error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
  };

  const getFunctions = async (id) => {
    const dados = {
      id_produto: id,
    };

    try {
      const data = await fetchData(
        `${url_api}/produtos/functions`,
        "POST",
        dados
      );
      console.log(data);
      if (data.result) {
        setModulos(data.data);
        return data.data;
      } else {
        openModal(
          "Lista Funcionalidades",
          data.message,
          () => onHide,
          types.alert
        );
      }
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal("Lista Funcionalidades", error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
  };

  const insertProduto = async () => {
    const dados = {
      id_empresa: cliente.id,
      id_produto: produto.id,
      nome_produto: produto.produto,
      nome: produto.name,
      valor_unid: +produto.valor + produtoPrecoBase,
      desconto: desc_acres,
      valor_total: calculaTotal(),
      observacao: obs,
      permissao: modulosPerm,
    };

    try {
      const data = await fetchData(
        `${url_api}/cliente/register/prod`,
        "POST",
        dados
      );
      console.log(data);
      if (data.result) {
        openModal(
          "Inserir novo Produto",
          data.message,
          () => onHide,
          types.alert
        );
      } else {
        openModal(
          "Inserir novo Produto",
          data.message,
          () => onHide,
          types.alert
        );
      }
      clearStadesProd();
      await getProdutosClientes();
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal("Inserir novo Produto", error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
  };

  const getProdutosClientes = async () => {
    try {
      const data = await fetchData(
        `${url_api}/cliente/lista/prod/${cliente.id}`
      );
      console.log(data);
      if (data.result) {
        setProdutosClientes(data.data);
      } else {
        openModal(
          "Lista Produtos Cliente",
          data.message,
          () => onHide,
          types.alert
        );
      }
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal("Lista Produtos Cliente", error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
  };

  const getProdutoClientes = async (id) => {
    try {
      const data = await fetchData(`${url_api}/cliente/filtra/prod/${id}`);
      console.log(data);
      if (data.result) {
        setProduto(data.data.produto[0]);
        setModulosPerm(data.data.venda[0].permissao);
        setObs(data.data.venda[0].observacao);
        setDesc_acres(data.data.venda[0].desconto);
        setId_venda(id);
        const model = await getFunctions(data.data.produto[0].id);
        console.log(model);
        calcularValorDoProdutoParaTodosModulos(
          data.data.venda[0].permissao,
          model
        );
      } else {
        openModal(
          "Editar Produto Cliente",
          data.message,
          () => onHide,
          types.alert
        );
      }
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal("Editar Produto Cliente", error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
  };

  const updateProdutoCliente = async (status, id) => {
    const dados = {
      status: status,
      id_venda: id,
    };

    const state = status === "Ativo" ? "Ativar" : "Cancelar";

    try {
      const data = await fetchData(
        `${url_api}/cliente/update/prod`,
        "PUT",
        dados
      );
      console.log(data);
      if (data.result) {
        openModal(`${state} produto`, data.message, () => onHide, types.alert);
      } else {
        openModal(`${state} produto`, data.message, () => onHide, types.alert);
      }
      getProdutosClientes();
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal(`${state} produto`, error, () => onHide, types.success);
      console.error("Erro ao enviar requisição:", error);
    }
  };

  const updateVendaCliente = async (id) => {
    const dados = {
      id_empresa: cliente.id,
      id_produto: produto.id,
      nome_produto: produto.produto,
      nome: produto.name,
      valor_unid: +produto.valor + produtoPrecoBase,
      desconto: desc_acres,
      valor_total: calculaTotal(),
      observacao: obs,
      permissao: modulosPerm,
      id_venda: id_venda,
    };

    try {
      const data = await fetchData(
        `${url_api}/cliente/update/prod`,
        "PUT",
        dados
      );
      console.log(data);
      if (data.result) {
        setIsEditableProd(false);
        openModal(
          `Modificar produto com id ${id_venda}`,
          data.message,
          () => onHide,
          types.alert
        );
      } else {
        openModal(
          `Modificar produto com id ${id_venda}`,
          data.message,
          () => onHide,
          types.alert
        );
      }
      getProdutosClientes();
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal(
        `Modificar produto com id ${id_venda}`,
        error,
        () => onHide,
        types.success
      );
      console.error("Erro ao enviar requisição:", error);
    }
    clearStadesProd();
  };

  const updateCliente = async () => {
    const dados = {
      cpf_cnpj: cliente.cpf_cnpj,
      date: dataPagamento,
    };

    try {
      const data = await fetchData(`${url_api}/cliente/update`, "PUT", dados);
      console.log(data);
      if (data.result) {
        openModal(
          `Alterou a data de Pagamento`,
          data.message,
          () => onHide,
          types.alert
        );
      } else {
        openModal(
          `Alterou a data de Pagamento`,
          data.message,
          () => onHide,
          types.alert
        );
      }
      // Manipular a resposta do servidor conforme necessário
    } catch (error) {
      openModal(
        `Alterou a data de Pagamento`,
        error,
        () => onHide,
        types.success
      );
      console.error("Erro ao enviar requisição:", error);
    }

    setIsEditable(false);
  };

  useEffect(() => {
    const requires = async () => {
      await getAllProducts();
      await getProdutosClientes();
    };
    requires();
  }, []);

  useEffect(() => {
    const validate = () => {
      if (cliente === "") {
        navigate(pathRoutes.listaCliente);
      }
    };
    validate();
  }, []);

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
  // Funções
  //===============================================================================================
  const handleProduto = async (e) => {
    let produto = "";
    if (e.target.value !== "") {
      produto = JSON.parse(e.target.value);
    }
    console.log(produto);
    setProduto(produto);
    setModulos([]);
    if (e.target.value !== "") {
      await getFunctions(produto.id);
    }
  };

  // Função para lidar com alterações no estado de um checkbox específico
  const handleCheckboxChange = (index) => {
    // Atualiza o estado modulosPerm com base no estado atual do checkbox
    const novoModulosPerm =
      modulosPerm.substring(0, index) +
      (modulosPerm[index] === "F" ? "T" : "F") +
      modulosPerm.substring(index + 1);
    setModulosPerm(novoModulosPerm);
    calcularValorDoProdutoParaTodosModulos(novoModulosPerm);
  };

  // Função para formatar o valor como moeda
  const formatarMoeda = (value) => {
    // Verifica se o valor é uma string
    console.log(value);
    if (typeof value !== "string") {
      value = String(value);
    }

    // Remove caracteres que não são números, exceto pontos decimais e o primeiro ponto decimal
    let valorNumerico = value.replace(/[^\d.]/g, "");

    // Se houver mais de um ponto decimal, mantenha apenas o primeiro
    const indexPontoDecimal = valorNumerico.indexOf(".");
    if (indexPontoDecimal !== -1) {
      valorNumerico =
        valorNumerico.slice(0, indexPontoDecimal + 1) +
        valorNumerico.slice(indexPontoDecimal + 1).replace(/\./g, "");
    }

    // Converte o valor para um número de ponto flutuante
    valorNumerico = Math.abs(parseFloat(valorNumerico));

    console.log(valorNumerico);

    // Formata o número como moeda (no caso, usando o Real brasileiro)
    return valorNumerico.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const handleInputChange = (valor) => {
    let valorDigitado = valor.target.value;

    if (typeof valorDigitado !== "string") {
      valorDigitado = valor.toString();
    }

    const valorNumerico =
      parseFloat(valorDigitado.replace(/[^\d]/g, "")) / 100 || 0;
    return valorNumerico;
  };

  const calculaTotal = () => {
    let valor = +produto.valor + produtoPrecoBase;
    let calculo = 0;
    if (aplicaDesc === true) {
      if (+desc_acres <= +valor) {
        calculo = +desc_acres - +valor;
      } else {
        setDesc_acres(0);
        return +valor;
      }
    } else {
      calculo = +valor + +desc_acres;
    }
    return Math.abs(calculo);
  };

  // Função para calcular o valor do produto para todos os módulos
  function calcularValorDoProdutoParaTodosModulos(
    novoModulosPerm,
    arrayModulos
  ) {
    const modul = arrayModulos ? arrayModulos : modulos;
    let valorDoProduto = 0;
    // Iterar sobre todos os módulos
    modul.forEach((modulo, index) => {
      // Verificar se há permissão ativada para este módulo
      if (novoModulosPerm[index] === "T") {
        // Calcular o valor do produto
        const moduloSelecionado = modul[index];
        valorDoProduto = +valorDoProduto + +moduloSelecionado.valor;

        // Mostrar informações do módulo e valor do produto
        console.log(`Informações do Módulo:`, moduloSelecionado);
        console.log(`Valor do Produto: ${valorDoProduto}`);
      }
    });
    setProdutoPrecoBase(parseFloat(valorDoProduto));
  }

  function calcularTotal(produtos) {
    const produtosAtivos = produtos.filter(
      (produto) => produto.status === "Ativo"
    );

    console.log(produtosAtivos);
    const soma = produtosAtivos.reduce(
      (total, produto) => +total + +produto.valor_total,
      0
    );
    return soma;
  }

  function calcularTotalCancelado(produtos) {
    const produtosAtivos = produtos.filter(
      (produto) => produto.status === "Cancelado"
    );

    console.log(produtosAtivos);
    const soma = produtosAtivos.reduce(
      (total, produto) => +total + +produto.valor_total,
      0
    );
    return soma;
  }

  function calcularTotalAberto(produtos) {
    const produtosAtivos = produtos.filter(
      (produto) => produto.status === "Aberto"
    );

    console.log(produtosAtivos);
    const soma = produtosAtivos.reduce(
      (total, produto) => +total + +produto.valor_total,
      0
    );
    return soma;
  }

  function formatarDataBrasileira(dataAmericana) {
    const [ano, mes, dia] = dataAmericana.split("-");
    return `${dia}/${mes}/${ano}`;
  }

  // ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓

  if (loading) {
    return <Loader isLoader={loading} />;
  }

  return (
    <>
      {showModal && (
        <MyModal
          show={showModal}
          onHide={onHide}
          type={type} // ou "success", "alert", "question", ou "error"
          title={title}
          body={body}
          onConfirm={handleConfirm}
        />
      )}
      <Container
        fluid="xxl"
        style={{
          background: "#000",
          display: "flex",
          color: "#fff",
          flexDirection: "column",
        }}
      >
        <Row style={{ paddingTop: "20px" }}>
          <Col>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <Button
                style={{
                  background: "red",
                  border: "none",
                  padding: "5px 30px ",
                }}
                onClick={() => navigate(pathRoutes.listaCliente)}
              >
                Voltar
              </Button>
              <h1>Produtos</h1>
            </div>
            <Divider style={{ background: "#fff", marginBottom: "10px" }} />
            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <h3>Dados do Cliente</h3>
              <TextCopy text={cliente.token} message={"Token de Instalação"} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "15px",
                alignItems: "baseline",
              }}
            >
              <p>ID: {cliente.id} </p>
              <p>|</p>
              <p>CPF/CNPJ: {cliente.cpf_cnpj}</p>
              <p>|</p>
              <p>Razão: {cliente.razao}</p>
              <p>|</p>
              <p>Fantasia: {cliente.fantasia}</p>
              <p>|</p>
              <p>
                Valor Cancelados:{" "}
                {formatarMoeda(calcularTotalCancelado(produtosCliente))}
              </p>
              <p>|</p>
              <p>
                Valor Aberto:{" "}
                {formatarMoeda(calcularTotalAberto(produtosCliente))}
              </p>
              <p>|</p>
              <p>
                Valor Ativo: {formatarMoeda(calcularTotal(produtosCliente))}
              </p>
              <p>|</p>
              {isEditable && (
                <Form.Group as={Row} style={{ alignItems: "baseline" }}>
                  <Form.Label style={{ width: "50%" }}>
                    Data Pagamento:
                  </Form.Label>
                  <Form.Control
                    style={{ width: "50%" }}
                    value={dataPagamento}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setDataPagamento(e.target.value);
                    }}
                    type="date"
                  />
                </Form.Group>
              )}
              {!isEditable && (
                <p>
                  Data Pagamento:{" "}
                  {dataPagamento
                    ? formatarDataBrasileira(dataPagamento)
                    : "Nao registrado"}{" "}
                </p>
              )}
              {!isEditable ? (
                <Button onClick={() => setIsEditable(true)}>Editar</Button>
              ) : (
                <>
                  <Button
                    style={{ background: "red", borderColor: "#aa0000" }}
                    onClick={() => {
                      setIsEditable(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button onClick={updateCliente}>Salvar</Button>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row style={{ display: "flex", flexGrow: 1 }}>
          <Col
            style={{
              background: "#005",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              gap: "15px",
              paddingTop: "20px",
            }}
          >
            <h2>Novo Produto</h2>
            <Divider style={{ background: "#fff" }} />
            <Form.Select
              aria-label="Default select example"
              onChange={handleProduto}
              disabled={isEditableProd}
              value={produto === "" ? "" : JSON.stringify(produto)}
            >
              <option value={""}>Selecione o Produto</option>
              {Array.isArray(produtos) &&
                produtos.map((e, i) => (
                  <option key={i} value={JSON.stringify(e)}>
                    {e.name}
                  </option>
                ))}
            </Form.Select>
            {Array.isArray(modulos) && modulos.length > 0 && (
              <div>
                <Divider style={{ background: "#fff", marginBottom: "10px" }} />
                <h2>Selecione os Modulos</h2>
                <div>
                  {modulos
                    .reduce((acc, curr, i) => {
                      if (i % 4 === 0) {
                        acc.push([]);
                      }
                      acc[acc.length - 1].push(curr);
                      return acc;
                    }, [])
                    .map((grupo, grupoIndex) => (
                      <div
                        key={grupoIndex}
                        style={{
                          display: "flex",
                        }}
                      >
                        {grupo.map((e, elementoIndex) => (
                          <div style={{ width: "25%" }}>
                            <Form.Check
                              key={elementoIndex}
                              type="switch"
                              id={`custom-switch${grupoIndex}-${elementoIndex}`}
                              checked={modulosPerm[e.index] === "T"}
                              onChange={() => handleCheckboxChange(e.index)}
                              label={e.nome}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            )}
            <Divider style={{ background: "#fff" }} />
            <h2>Pagamento</h2>
            <Form.Check // prettier-ignore
              type="switch"
              id={`custom-switch`}
              checked={aplicaDesc}
              onChange={(e) => setAplicaDesc(e.target.checked)}
              label={aplicaDesc ? "Aplicando Desconto" : "Aplicando Acrescimo"}
            />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  value={formatarMoeda(+produto.valor + produtoPrecoBase || 0)}
                  disabled
                  type="text"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Desc/Acres</Form.Label>
                <Form.Control
                  value={formatarMoeda(desc_acres) || 0}
                  onChange={(e) => setDesc_acres(handleInputChange(e))}
                  type="text"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Total</Form.Label>
                <Form.Control
                  disabled
                  value={formatarMoeda(calculaTotal() || 0)}
                  type="text"
                />
              </Form.Group>
            </Row>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Observação</Form.Label>
              <Form.Control
                style={{ resize: "none" }}
                value={obs}
                onChange={(e) => setObs(e.target.value)}
                as="textarea"
                rows={3}
              />
            </Form.Group>
            {isEditableProd ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  style={{ background: "red", width: "40%", border: "none" }}
                  onClick={() => {
                    clearStadesProd();
                    setIsEditableProd(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  style={{ background: "green", width: "40%", border: "none" }}
                  onClick={() => {
                    updateVendaCliente();
                  }}
                >
                  Salvar
                </Button>
              </div>
            ) : (
              <Button
                style={{ background: "green", border: "none" }}
                onClick={insertProduto}
              >
                Adicionar
              </Button>
            )}
          </Col>
          <Col
            xs={6}
            style={{
              background: "#099",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              backgroundColor: "lightblue",
              gap: "15px",
              paddingTop: "20px",
              color: "rgb(0, 0, 85)",
            }}
          >
            <h2>Lista de Produtos</h2>
            <Divider style={{ background: "rgb(0, 0, 85)" }} />
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Status</th>
                  <th>Produto</th>
                  <th>Valor</th>
                  <th>Ult.Acesso</th>
                  <th style={{ textAlign: "center" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(produtosCliente) &&
                  produtosCliente.map((e, i) => (
                    <tr key={i}>
                      <td
                        style={{
                          fontWeight: "800",
                          color: "#fff",
                          alignContent: "center",
                          background:
                            e.status === "Ativo"
                              ? "green"
                              : e.status === "Cancelado"
                              ? "red"
                              : "blue",
                        }}
                      >
                        {e.id}
                      </td>
                      <td
                        style={{
                          fontWeight: "800",
                          color: "#fff",
                          alignContent: "center",
                          background:
                            e.status === "Ativo"
                              ? "green"
                              : e.status === "Cancelado"
                              ? "red"
                              : "blue",
                        }}
                      >
                        {e.status}
                      </td>
                      <td
                        style={{
                          fontWeight: "800",
                          color: "#fff",
                          alignContent: "center",
                          background:
                            e.status === "Ativo"
                              ? "green"
                              : e.status === "Cancelado"
                              ? "red"
                              : "blue",
                        }}
                      >
                        {e.nome}
                      </td>
                      <td
                        style={{
                          fontWeight: "800",
                          color: "#fff",
                          alignContent: "center",
                          background:
                            e.status === "Ativo"
                              ? "green"
                              : e.status === "Cancelado"
                              ? "red"
                              : "blue",
                        }}
                      >
                        {formatarMoeda(e.valor_total)}
                      </td>
                      <td
                        style={{
                          fontWeight: "800",
                          color: "#fff",
                          alignContent: "center",
                          background:
                            e.status === "Ativo"
                              ? "green"
                              : e.status === "Cancelado"
                              ? "red"
                              : "blue",
                        }}
                      >
                        {e.ultimo_horario}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <DropdownButton
                          id="dropdown-basic-button"
                          title="Ações"
                        >
                          <Dropdown.Item
                            style={{
                              background: "orange",
                              color: "#fff",
                              padding: "10px 5px",
                              textAlign: "center",
                            }}
                            onClick={() => {
                              if (
                                e.observacao === null ||
                                e.observacao === ""
                              ) {
                                openModal(
                                  "Ver OBS",
                                  "Não Possui Observação",
                                  () => onHide,
                                  types.success
                                );
                              } else {
                                openModal(
                                  "Ver OBS",
                                  e.observacao,
                                  () => onHide,
                                  types.success
                                );
                              }
                            }}
                          >
                            Observações
                          </Dropdown.Item>

                          {e.status === "Ativo" && (
                            <>
                              <Dropdown.Item
                                style={{
                                  background: "red",
                                  color: "#fff",
                                  padding: "10px 5px",
                                  textAlign: "center",
                                }}
                                onClick={() =>
                                  updateProdutoCliente("Cancelado", e.id)
                                }
                              >
                                Cancelar
                              </Dropdown.Item>
                            </>
                          )}
                          {e.status === "Inativo" ||
                          e.status === "Aberto" ||
                          e.status === "Cancelado" ? (
                            <>
                              <Dropdown.Item
                                style={{
                                  background: "green",
                                  color: "#fff",
                                  padding: "10px 5px",
                                  textAlign: "center",
                                }}
                                onClick={() =>
                                  updateProdutoCliente("Ativo", e.id)
                                }
                              >
                                Ativar
                              </Dropdown.Item>
                            </>
                          ) : (
                            ""
                          )}
                          <Dropdown.Item
                            style={{
                              background: "blue",
                              color: "#fff",
                              padding: "10px 5px",
                              textAlign: "center",
                            }}
                            onClick={async () => {
                              await getProdutoClientes(e.id);
                              setIsEditableProd(true);
                            }}
                          >
                            Editar
                          </Dropdown.Item>
                        </DropdownButton>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Vendas;
