import React from "react";
import pathRoutes from "../../path/pathRoutes";
import { useNavigate } from "react-router-dom";

import "../style/NotFound.css";
// Componente NotFound
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="not-found-container">
      <h1 className="title">404 - Página não encontrada</h1>
      <p className="description">Parece que você se perdeu no futuro...</p>
      <button onClick={() => navigate(pathRoutes.auth)} className="button">
        Voltar para casa
      </button>
    </div>
  );
};

export default NotFound;
